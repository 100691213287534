<template>
  <v-card
    flat
    class="rounded-0 fill-height d-flex align-stretch justify-center"
    :class="{ 'flex-column': !mdAndUp }"
  >
    <v-sheet
      min-height="100vh"
      :class="{ fullwidth: !mdAndUp, halfwidth: mdAndUp }"
      class="d-flex px-10"
      color="primary"
    >
      <v-card
        flat
        max-width="450"
        color="transparent"
        class="align-self-center mx-auto fullwidth"
      >
        <v-card-title class="d-flex fullwidth flex-column">
          <v-img
            :lazy-src="settings.loader"
            max-width="300"
            :src="settings.longLogoWhite"
          ></v-img>
          <h2 class="light--text fw-900 fs-25">Signup</h2>
        </v-card-title>
        <v-form v-model="valid">
          <v-card-text v-if="signup_enabled">
            <v-row dense>
              <v-col cols="12">
                <label class="light--text fw-700 fs-15">Company Name</label>
                <v-text-field
                  v-model="company_name"
                  :rules="requiredRules"
                  dense
                  color="white"
                  filled
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-alpha-c-box"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="light--text fw-700 fs-15">First Name</label>
                <v-text-field
                  v-model="first_name"
                  :rules="requiredRules"
                  dense
                  color="white"
                  filled
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <label class="light--text fw-700 fs-15">Last Name</label>
                <v-text-field
                  v-model="last_name"
                  dense
                  color="white"
                  filled
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-account"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="light--text fw-700 fs-15">Email</label>
                <v-text-field
                  v-model="email"
                  :rules="requiredRules"
                  dense
                  color="white"
                  filled
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-email"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="light--text fw-700 fs-15">Password</label>
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  v-model="password"
                  :rules="requiredRules"
                  dense
                  color="white"
                  filled
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-lock"
                  append-icon="mdi-eye"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <label class="light--text fw-700 fs-15">Confirm Password</label>
                <v-text-field
                  :type="showPassword ? 'text' : 'password'"
                  v-model="confirm_password"
                  :rules="[passwordConfirmationRule(password)]"
                  dense
                  color="white"
                  filled
                  clearable
                  clear-icon="mdi-close-circle-outline"
                  dark
                  hide-details="auto"
                  outlined
                  prepend-inner-icon="mdi-lock-open"
                  append-icon="mdi-eye"
                  @click:append="showPassword = !showPassword"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <div
            v-else
            class="
              white--text
              d-flex
              align-center
              justify-center
              flex-column
              text-center
            "
          >
            <span>
              Signup is temporary unavailable. <br />
              Please try again later
            </span>
            <v-btn
              @click="$router.push({ name: 'login' })"
              color="secondary"
              class="text-none my-4 px-5"
            >
              Back to login
            </v-btn>
          </div>
          <v-card-actions
            v-if="signup_enabled"
            class="d-flex fullwidth flex-column"
          >
            <v-btn
              color="accent"
              elevation="5"
              class="fw-900 py-5 rounded-20 mb-3 light--text ls-unset fs-20"
              :disabled="!valid"
              block
              @click="signup"
            >
              SIGN UP
            </v-btn>
            <div class="my-2 light--text" v-if="signup_enabled">
              <label>
                Already had account?
                <v-btn
                  link
                  depressed
                  class="text-none light--text"
                  text
                  @click="goSignin"
                >
                  Signin
                </v-btn>
              </label>
            </div>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-sheet>
    <v-sheet
      :min-height="mdAndUp ? `100vh` : ``"
      class="d-flex fill-height fullwidth"
      color="white"
    >
      <v-img
        :lazy-src="settings.loader"
        contain
        :src="require('@/assets/login/gallery-2.png')"
      ></v-img>
    </v-sheet>
  </v-card>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  inheritAttrs: false,
  data: () => ({
    valid: false,
    showPassword: false,
    email: null,
    password: null,
    confirm_password: null,
    company_name: null,
    first_name: null,
    last_name: null
  }),
  computed: {
    ...mapGetters('app', ['allowedModules']),
    signup_enabled() {
      return this.allowedModules.includes('signup')
    },
    redirect_uri() {
      return this.$route.query.hasOwnProperty('redirect_uri')
        ? this.$route.query.redirect_uri
        : null
    },
    payload() {
      return {
        company_name: this.company_name,
        first_name: this.first_name,
        last_name: this.last_name,
        email: this.email,
        password: this.password
      }
    }
  },
  methods: {
    goToSite() {
      window.location = this.settings.wp_site
    },
    goSignin() {
      if (this.signup_enabled) {
        this.$router.push({
          name: 'login',
          params: { redirect_uri: this.redirect_uri }
        })
      } else {
        this.appSnackbar(
          'Signup is temporary unavailable. Please try again later'
        )
      }
    },
    signup() {
      this.$auth.register(
        this.payload,
        (data) => {
          this.$router.push({ name: 'default-content' })
        },
        (error) => {
          console.error(error)
        }
      )
    }
  }
}
</script>

<style></style>
